<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="edit-match-pool-sidebar"
      v-model="openSideBar"
      title="Add Participant"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="edit-match-pool-sidebar"
      :aria-expanded="openSideBar"
      width="55%"
      @hidden="onHidden"
    >
      <validation-observer
        ref="updateTrainingUserObserver"
      >
        <section>
          <b-card>
            <b-form>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    label="Search"
                    label-for="Search"
                  >
                    <b-input-group
                      class="mb-2"
                    >
                      <b-input-group-prepend class="search-icon">
                        <feather-icon
                          icon="SearchIcon"
                          size="14"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="`type-search`"
                        v-model="search"
                        type="search"
                        placeholder="Search"
                        class="pool-search"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-if="isLoading">
                <aom-skeleton-loader />
              </div>
              <b-row>
                <b-col sm="6">
                  <h5 class="mt-50 mb-50">
                    Participants {{ selectedUsersCount }}
                  </h5>
                  <b-row>
                    <b-col sm="12">
                      <b-form-checkbox
                        v-model="isSelectAllUsers"
                        :disabled="users.length > seatsAvailable"
                        class="mt-1 mb-1"
                        @change="checkAllUsers()"
                      >
                        Select All
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <div v-if="(users.length === 0 && !isLoading)">
                    <h5 class="text-muted text-center mt-3">
                      No results found.
                    </h5>
                  </div>
                  <b-list-group
                    v-for="(user, index) in users"
                    :key="index"
                  >
                    <b-list-group-item class="d-flex align-items-center"> 
                      <b-form-group
                        v-slot="{ ariaDescribedby }"
                        class="mb-0"
                      >
                        <div class="d-flex">
                          <b-form-checkbox-group
                            v-model="selectedUsers"
                            :aria-describedby="ariaDescribedby"
                            name="selected-match"
                            class="mt-2"
                            @change="updateCheckAll"
                          >
                            <b-form-checkbox
                              :value="{id: user.id}"
                              :disabled="disableUser(user.id)"
                            />
                          </b-form-checkbox-group>
                          <div class="left-flex mr-1 ml-1">
                            <b-avatar
                              variant="primary"
                              :text="userInitials(user)"
                              size="3rem"
                              class="left-flex"
                            />
                          </div>
                          <div
                            class="right-flex"
                          >
                            <h5 class="mt-50 mb-0">
                              {{ user.full_name }}
                              <span
                                v-if="userDisabledTooltip(user.id)"
                                v-b-tooltip.hover.top="`This user has already commenced their training and cannot be unassigned.`"
                                class="disabled-icon ml-2"
                                variant="primary"
                              >
                                <feather-icon
                                  icon="InfoIcon"
                                  size="20"
                                />
                              </span>
                            </h5>
                            <p class="text-muted mb-0">
                              <small>{{ user.email }}</small>
                            </p>
                            <p class="mb-0">
                              <small>{{ roleAlias(user.role_id, defaultProgram) }}</small>
                            </p>
                          </div>
                        </div>
                      </b-form-group>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Seats Allocated"
                    label-for="licence-end-date"
                  >
                    <div
                      class="bag-button btn-common secondary-bg-color"
                    >
                      <b-badge :variant="STATUS_COLOR['Applied']">
                        {{ this.selectedUsers.length }} / {{ seatsAvailable }}
                      </b-badge>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </section>
      </validation-observer>
      <template #footer="{ hide }">
        <b-row
          v-if="!isLoading"
          class="ml-1 mr-1 pt-1 border-top"
        >
          <b-col
            sm="12"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="hide"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="5"
            class="mb-1"
          >
            <b-button
              type="submit"
              block
              variant="primary"
              :disabled="isDisabled"
              @click="update"
            >
              <feather-icon
                v-if="!isUpdating"
                size="16"
                icon="SaveIcon"
                class="mr-50"
              />
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span
                v-if="isUpdating"
              > Updating...</span>
              <span v-else>Update</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BSidebar,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BListGroupItem,
  BListGroup,
  BAvatar,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import matchesService from '@/services/matchesService';
import { mapGetters } from 'vuex';
import { makeSuccessToast, makeErrorToast, STATUS_COLOR } from "@/libs/utils";
import { userRoles, userRolesDisplay, groupTypeDisplay, MatchingPoolClass, trainingStatus, userStatus } from '@models';
import _debounce from "lodash/debounce";
import { getValidationState } from "@/libs/utils";
import vSelect from "vue-select";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { useNumberOfMentorsAllowed } from '../matches/useNumberOfMentorsAllowed';
import { roleAlias } from "@/@aom-core/utils/utils";
import { useHasUserReachedMatchLimit } from "../matches/useHasUserReachedMatchLimit";
import { trainingsService, usersService } from "@/services";

export default {
  name: 'ParticipantTrainingUserList',
  components: {
    BRow,
    BBadge,
    BCol,
    BSidebar,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BCard,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BListGroupItem,
    BListGroup,
    BAvatar,
    AomSkeletonLoader
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    seatsAvailable: {
      type: Number,
      default: () => 0
    }
  },
  data(vm) {
    return {
      openSideBar: vm.open,
      isLoading: false,
      isUpdating: false,
      poolData: this.pool,
      users: [],
      selectedUsers: [],
      groups: [],
      selectedGroups: [],
      search: '',
      userRolesDisplay,
      groupTypeDisplay,
      matchesPerMentor: undefined,
      isSelectAllUsers: false,
      isSelectAllGroups: false,
      numberOfMentorsAllowed: [],
      allocatedUsers: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'defaultProgramId']),
    userProfileImage () {
      return this.user?.profile_image?.url || '';
    },
    isDisabled() {
      return this.selectedUsers.length === 0;
    },
    reciprocalRoleInMatch() {
      return Number(this.role) === Number(userRoles.MENTEE) ? userRoles.MENTOR : userRoles.MENTEE;
    },
    selectedUsersCount () {
      return Number(this.selectedUsers.length);
    },
    selectedGroupsCount () {
      return Number(this.selectedGroups.length);
    },
    matchesPerMentorLabel() {
      return "Matches per " + roleAlias(userRoles.MENTOR, this.defaultProgram);
    },
    matchesPerMentorDescription() {
      return "Restricts how many "+roleAlias(userRoles.MENTEE, this.defaultProgram).toLowerCase()+"s a "+roleAlias(userRoles.MENTOR, this.defaultProgram).toLowerCase()+" can be paired with in this pool";
    }
  },
  watch: {
    open() {
      this.loadCandidates();
      this.openSideBar = this.open;
    },
    openSideBar(n) {
      if(!n) {
        this.$emit('toggleSideBar', n);
      }
    },
    search: _debounce(function(newVal){
      this.loadLists(newVal);
    }, 1000),
  },
  methods: {
    setSelectedUsers (allocatedUsers) {
      this.selectedUsers = allocatedUsers.map(user => { return {id: user.id};});
    },
    disableUser (userId) {
      let trainingInfo = this.allocatedUsers.find(user => user.id === userId);
      return (this.selectedUsers.length >= this.seatsAvailable && !this.selectedUsers.find(user => user.id === userId))
      || (trainingInfo?.program_training_users.length > 0 && trainingInfo?.program_training_users[0].status_id !== trainingStatus.NOT_STARTED);
    },
    userDisabledTooltip (userId) {
      let trainingInfo = this.allocatedUsers.find(user => user.id === userId);
      return trainingInfo?.program_training_users.length > 0 && trainingInfo?.program_training_users[0].status_id !== trainingStatus.NOT_STARTED;
    },
    checkAllUsers() {
      this.selectedUsers = [];
      if(this.isSelectAllUsers) {
        this.users.forEach(user => {
          this.selectedUsers.push({id: user.id});
        });
      }
    },
    updateCheckAll () {
      this.isSelectAllUsers = this.selectedUsers.length === this.users.length;
    },
    loadLists(search) {
      this.loadCandidates(search);
    },
    async loadCandidates(search)  {

      const filter = {
        columnFilters: [
          {field: "status",value: [userStatus.ACTIVE, userStatus.INACTIVE]}
        ],
      };
      if(search) {
        filter.columnFilters.push({field: "full_name",value: search});
      }

      try {
        const programId = this.defaultProgramId;
        this.isLoading = true;
        this.users = [];
        const response = await usersService.getListParticipantsByProgram(programId, filter, false);
        const allocatedUsers = await trainingsService.getTrainingUserList(
          programId,
          this.$route.params.trainingId
        );
        this.allocatedUsers = allocatedUsers.data.items;
        const { data } = response;
        this.users = data.items;
        this.setSelectedUsers(allocatedUsers.data.items);
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error. Fetching candidates list'));
        this.$log.error(e);
      }finally {
        this.isLoading = false;
      }
    },
    async update() {
      const isValid = await this.$refs.updateTrainingUserObserver.validate();
      if (!isValid) {
        return;
      }
      try {
        this.isUpdating = true;
        await trainingsService.updateSeatAllocated(this.defaultProgramId, this.$route.params.trainingId,  {
          users: this.selectedUsers,
        });
        this.$toast(makeSuccessToast("Trainee updated successfully."));
        this.$emit('update', true);
        this.openSideBar = false;
        this.onHidden()
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong."));
        console.log(e);
      } finally {
        this.isUpdating = false;
      }
    },
    userInitials(user) {
      const firstInt = Array.from(user.first_name)[0];
      const lastInt = Array.from(user.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    onHidden() {
      this.openSideBar = false;
      this.isLoading = false,
      this.isUpdating = false;
      this.matchedUsers = [];
      this.selectedMatch = undefined;
      this.users = [];
      this.selectedUsers = [];
      this.groups = [];
      this.selectedGroups = [];
      this.search = '';
      this.matchesPerMentor = undefined;
      this.$emit('close', true);
    }
  },
  setup() {
    return {
      getValidationState,
      useHasUserReachedMatchLimit,
      roleAlias,
      STATUS_COLOR
    };
  }
};
</script>

<style lang="scss">
    $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
        flex-direction: row-reverse;
        background: #f3f3f3;
        color: $sidebar-header-text-color;
    }
    .full-width {
        width: 100%
    }
    .border-top {
        border-top: 1px solid #ccc;
    }
    .list-group-item {
        .form-group {
            flex:2;
            .custom-control-label {
                width: 100%;
            }
        }
    }
    .search-icon {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        border-right: none;
        margin-right:-5px;
    }
    .pool-search {
        border-left: none;
    }
    .right-flex {
      overflow: auto;
    }
</style>
<style lang="scss" scoped>
   .card {
          box-shadow: none;
      }
  </style>